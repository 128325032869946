
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
import Switches from "vue-switches";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Switches
  },
  data() {
    return {
      user_notifications: [],

      NotificationSettings: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchNotificationSettings: null,
      NotificationSettingsSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    switchStatus(app) {
      // this.status = !this.status
      app.status = !app.status;
      this.http.put("notification-settings", app?.id, { status: app.status }).then((res) => {
        if (res.status) {
          this.get(this.page);
        }
      });
    },
    addNotificationSettings() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.add",
        inputs: [
          { model: "name", type: "text", label: "notification_settings.name" },
          {
            model: "topic",
            type: "text",
            label: "notification_settings.topic",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("notification-settings", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editNotificationSettings(app) {
      var data = {
        title: "popups.notification_settings",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "notification_settings.name",
            value: app.name,
          },
          {
            model: "topic",
            type: "text",
            label: "notification_settings.topic",
            value: app.topic,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("notification-settings", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateNotificationSettings(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "name", type: "text", label: "notification_settings.name" },
          {
            model: "topic",
            type: "text",
            label: "notification_settings.topic",
          },
          {
            model: "status",
            type: "text",
            label: "notification_settings.status",
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("notification-settings", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.NotificationSettingsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("notification-settings/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.NotificationSettings = res.data;
          console.log("######## files", this.NotificationSettings);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.NotificationSettingsSearchMode = false;
      this.get(this.page);
    },

    getNotificationSettings() {
      this.http.get("notification-settings").then((res) => {
        this.NotificationSettings = res.data;
      });
    },
    deleteNotificationSettings(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("notification-settings", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("notification-settings/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.NotificationSettings = res.data;
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.notification_settings.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="NotificationSettingsSearchMode"
          @click="cancelappsearchMode()"
        ></button>
        <button
          type="button"
          @click="addNotificationSettings()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("notification_settings.id") }}</th>
          <th scope="col">{{ $t("notification_settings.name") }}</th>
          <th scope="col">{{ $t("notification_settings.topic") }}</th>
          <th scope="col">{{ $t("notification_settings.status") }}</th>
          <th scope="col">{{ $t("notification_settings.created") }}</th>
          <th scope="col">{{ $t("notification_settings.updated") }}</th>
          <th scope="col">{{ $t("popups.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(app, index) in NotificationSettings"
          :key="app"
          class="text-center"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ app.name }}</td>
          <td>{{ app.topic }}</td>
          <td>
            <switches
              @change="switchStatus(app)"
              :value="app.status"
              color="success"
              class="ms-1"
            ></switches>
          </td>
          <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td>

          <td class="d-flex">
            <a
              class="btn btn-primary mx-1"
              @click="editNotificationSettings(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger"
              style="display: none"
              href="javascript: void(0);"
              role="button"
              @click="deleteNotificationSettings(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




